<template>
  <transition appear name="fade">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div id="space-filler"></div>
        <appAdminSidebar></appAdminSidebar>
        <appHeader class=""></appHeader>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <div class="border-right p-3">
          <h2>Add One Card at a Time</h2>
          <div class="add-cards rounded p-2">
            <div class="row pt-2">
              <div class="my-1 col-lg col-sm-4">
                <label>Value</label>
                <b-input-group size="md" prepend="$">
                  <b-form-input
                    v-model.number="newCardValue"
                    class="form-control"
                    min="0"
                    required
                    type="number"
                  ></b-form-input>
                  <div class="invalid-feedback">Required</div>
                </b-input-group>
              </div>
              <div class="my-1 col-lg col-sm-4">
                <label>User name</label>
                <b-form-input
                  v-model="newCardName"
                  class="form-control"
                  min="0"
                  required
                  type="text"
                ></b-form-input>
                <div class="invalid-feedback">Required</div>
              </div>
              <div class="my-1 col-lg col-sm-4">
                <label>Email</label>
                <b-form-input
                  v-model="newCardEmail"
                  class="form-control"
                  min="0"
                  required
                  type="email"
                ></b-form-input>
                <div class="invalid-feedback">Required</div>
              </div>
              <div class="my-1 col-lg col-sm-4">
                <label>Prepaid Card Type</label>
                <b-form-select
                  v-model="newCardPrepaidType"
                  class="form-control"
                  :options="cardOptions"
                  style="background-color: white;"
                  required
                ></b-form-select>
                <div class="invalid-feedback">Required</div>
              </div>
              <div class="col-lg-1 col-sm-1"></div>
            </div>
            <div class="mt-3">
              <button
                class="btn btn-primary btn-md btn-sm-block"
                type="button"
                v-on:click="
                  prepareNewCard(newCardValue, newCardName, newCardEmail, newCardPrepaidType)
                "
              >
                Add one more card
              </button>
            </div>
          </div>
          <div class="card-container text-right mt-2"></div>
        </div>
        <div class="p-3 m-3">
          <h2>Upload a CSV</h2>
          <div class="d-flex">
            <b-form-file
              accept=".csv"
              class="mt-2"
              v-model="csvFile"
              :state="Boolean(csvFile)"
              placeholder="Upload a CSV File"
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <b-spinner
              v-if="processingCSV"
              class="m-2"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </div>
      <div
        class="align-self-center text-danger"
        id="errors"
        v-if="errorStrings && errorStrings.length > 0"
      >
        <p class="" :key="errorStrings.length">ERRORS FOUND!</p>
        <li v-for="error in errorStrings">
          {{ error }}
        </li>
      </div>
      <b-table
        v-if="preparedNewCards.length > 0"
        class="mx-5"
        :fields="fields"
        fixed
        striped
        bordered
        :items="preparedNewCards"
      >
        <template v-slot:cell(Actions)="{ item }">
          <button
            class="btn btn-tertiary btn-md btn-sm-block mr-3"
            type="button"
            @click="removePreparedNewCard(item)"
          >
            <font-awesome-icon
              class="mr-1"
              size="lg"
              icon="times"
              transform="enlarge-1"
            />
          </button>
        </template>
      </b-table>
      <b-button
        v-if="preparedNewCards.length > 0"
        variant="primary"
        class="flex-grow-0 align-self-end mx-5"
        type="button"
        v-on:click="nextStep"
        :disabled="!canGoToNextStep"
      >
        Next step
      </b-button>
      <appFooter class="pt-4 mt-5"></appFooter>
    </div>
  </transition>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
import AdminSidebar from "@/views/admin/AdminSidebar";
import Papa from 'papaparse';

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    appAdminSidebar: AdminSidebar,
  },
  data() {
    return {
      newCardValue: null,
      newCardName: null,
      newCardEmail: null,
      newCardPrepaidType: null,
      csvFile: null,
      processingCSV: false,
      fields: ["orderId", "userName", "userEmail", "value", "prepaidCardType", "Actions"],
      errorStrings: [],
      newCardPrepaidType: 'Visa',  // This will hold the selected value (Visa or Mastercard)
      cardOptions: [
        { value: 'Visa', text: 'Visa' },
        { value: 'Mastercard', text: 'Mastercard' }
      ]
    };
  },
  watch: {
    csvFile(newFile, oldFile) {
      this.processingCSV = true;
      this.errorStrings = [];

      this.readCSV();
    },
  },
  computed: {
    ...mapState({
      preparedNewCards: (state) => state.admin.preparedNewCards,
    }),
    canGoToNextStep: function () {
      return this.preparedNewCards.length > 0;
    },
  },
  methods: {
    prepareNewCard: function (value, name, email, prepaidCardType) {
      this.$store.dispatch("admin/prepareNewCard", {
        value: value,
        userName: name,
        userEmail: email,
        prepaidCardType: prepaidCardType,
      });
    },
    removePreparedNewCard: function (item) {
      this.$store.dispatch("admin/removePreparedNewCard", {
        item: item,
      });
    },
    emptyPreparedNewCards: function (value, name, email) {
      this.$store.dispatch("admin/emptyPreparedNewCards");
    },
    nextStep: function () {
      this.$router.push({ name: "AdminCardsCreate2" });
    },
    readCSV: async function () {
      // read the file as a sring
      const data = await this.csvFile.text();
      // parse csv to a list of JSON objects
      const csvArrayData = Papa.parse(data,{fastMode: false})

      const csvArray = csvArrayData.data; 

      const numUsers = csvArray.length - 1; // remove 1 for headers row

      const cards = [];

      // -1 due to index based
      for (let i = 1; i <= numUsers; i++) {
        // email format: Visa Gift card ($25.00 USD to Alanda Powell &amp;lt;user@email.com&amp;gt;)
        const description = csvArray[i][38]

        if (!description) {
          console.error(
            "Description column not found! Username and email can't be parsed"
          );

          this.errorStrings.push(
            `Couldn't parse description column for user #${i}.`
          );
          continue; // still try to parse the other users
        }

        let pFrom = description.indexOf("&amp;lt;") + "&amp;lt;".length;
        let pTo = description.indexOf("&amp;gt;");

        const emailParsed = description.substring(pFrom, pTo);

        // shows 103% of value, so need to get gift card value
        const valueGiftCard = parseFloat(
          (csvArray[i][28].replace(/['"]+/g, "").replace(',','') / 1.03).toFixed(2)
        );

        // user names are only in the description section
        // format: Visa Gift card ($25.00 USD to Alanda Powell &amp;lt;user@email.com&amp;gt;)
        pTo = pFrom - "&amp;lt;".length;
        pFrom = description.indexOf("to") + "to".length;

        const userNameParsed = description.substring(pFrom, pTo).trim();

        cards[i - 1] = {
          orderId: `${csvArray[i][0]}-${i}`, // Tango does not allow the same external ref ID on multiple orders
          userName: userNameParsed,
          userEmail: emailParsed,
          value: valueGiftCard,
        };
      }
      this.processingCSV = false;
      this.$store.dispatch("admin/prepareNewCards", cards);
    },
  },

  created() {},
};
</script>

<style lang="scss">
.new-row {
  div {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 8px;
    border-radius: $border-radius-sm;
  }
  .btn {
    color: $primary;
  }
}
.add-cards {
  background-color: #f0f9ff;
  input {
    background-color: white;
  }
}
#cardList {
  li {
    list-style: none;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column" },
      [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c("div", { attrs: { id: "space-filler" } }),
            _c("appAdminSidebar"),
            _c("appHeader", {})
          ],
          1
        ),
        _c("div", { staticClass: "d-flex justify-content-center mb-3" }, [
          _c("div", { staticClass: "border-right p-3" }, [
            _c("h2", [_vm._v("Add One Card at a Time")]),
            _c("div", { staticClass: "add-cards rounded p-2" }, [
              _c("div", { staticClass: "row pt-2" }, [
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-4" },
                  [
                    _c("label", [_vm._v("Value")]),
                    _c(
                      "b-input-group",
                      { attrs: { size: "md", prepend: "$" } },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: { min: "0", required: "", type: "number" },
                          model: {
                            value: _vm.newCardValue,
                            callback: function($$v) {
                              _vm.newCardValue = _vm._n($$v)
                            },
                            expression: "newCardValue"
                          }
                        }),
                        _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("Required")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-4" },
                  [
                    _c("label", [_vm._v("User name")]),
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: { min: "0", required: "", type: "text" },
                      model: {
                        value: _vm.newCardName,
                        callback: function($$v) {
                          _vm.newCardName = $$v
                        },
                        expression: "newCardName"
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Required")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-4" },
                  [
                    _c("label", [_vm._v("Email")]),
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: { min: "0", required: "", type: "email" },
                      model: {
                        value: _vm.newCardEmail,
                        callback: function($$v) {
                          _vm.newCardEmail = $$v
                        },
                        expression: "newCardEmail"
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Required")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-4" },
                  [
                    _c("label", [_vm._v("Prepaid Card Type")]),
                    _c("b-form-select", {
                      staticClass: "form-control",
                      staticStyle: { "background-color": "white" },
                      attrs: { options: _vm.cardOptions, required: "" },
                      model: {
                        value: _vm.newCardPrepaidType,
                        callback: function($$v) {
                          _vm.newCardPrepaidType = $$v
                        },
                        expression: "newCardPrepaidType"
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Required")
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "col-lg-1 col-sm-1" })
              ]),
              _c("div", { staticClass: "mt-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-md btn-sm-block",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.prepareNewCard(
                          _vm.newCardValue,
                          _vm.newCardName,
                          _vm.newCardEmail,
                          _vm.newCardPrepaidType
                        )
                      }
                    }
                  },
                  [_vm._v(" Add one more card ")]
                )
              ])
            ]),
            _c("div", { staticClass: "card-container text-right mt-2" })
          ]),
          _c("div", { staticClass: "p-3 m-3" }, [
            _c("h2", [_vm._v("Upload a CSV")]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("b-form-file", {
                  staticClass: "mt-2",
                  attrs: {
                    accept: ".csv",
                    state: Boolean(_vm.csvFile),
                    placeholder: "Upload a CSV File",
                    "drop-placeholder": "Drop file here..."
                  },
                  model: {
                    value: _vm.csvFile,
                    callback: function($$v) {
                      _vm.csvFile = $$v
                    },
                    expression: "csvFile"
                  }
                }),
                _vm.processingCSV
                  ? _c("b-spinner", {
                      staticClass: "m-2",
                      attrs: { variant: "primary", label: "Spinning" }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _vm.errorStrings && _vm.errorStrings.length > 0
          ? _c(
              "div",
              {
                staticClass: "align-self-center text-danger",
                attrs: { id: "errors" }
              },
              [
                _c("p", { key: _vm.errorStrings.length }, [
                  _vm._v("ERRORS FOUND!")
                ]),
                _vm._l(_vm.errorStrings, function(error) {
                  return _c("li", [_vm._v(" " + _vm._s(error) + " ")])
                })
              ],
              2
            )
          : _vm._e(),
        _vm.preparedNewCards.length > 0
          ? _c("b-table", {
              staticClass: "mx-5",
              attrs: {
                fields: _vm.fields,
                fixed: "",
                striped: "",
                bordered: "",
                items: _vm.preparedNewCards
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(Actions)",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-tertiary btn-md btn-sm-block mr-3",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removePreparedNewCard(item)
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "mr-1",
                              attrs: {
                                size: "lg",
                                icon: "times",
                                transform: "enlarge-1"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                4144376288
              )
            })
          : _vm._e(),
        _vm.preparedNewCards.length > 0
          ? _c(
              "b-button",
              {
                staticClass: "flex-grow-0 align-self-end mx-5",
                attrs: {
                  variant: "primary",
                  type: "button",
                  disabled: !_vm.canGoToNextStep
                },
                on: { click: _vm.nextStep }
              },
              [_vm._v(" Next step ")]
            )
          : _vm._e(),
        _c("appFooter", { staticClass: "pt-4 mt-5" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }